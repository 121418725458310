import * as React from 'react'
import { useStore } from 'store/context'
import { Package } from 'store/modules/users'

type PackageProtectProps = {
  children: React.ReactNode
  include?: Package[]
  exclude?: Package[]
}

export const PackageProtect: React.VFC<PackageProtectProps> = ({
  children,
  include = ['grow'],
  exclude = [],
}) => {
  const { nonNullCurrentUser: currentUser } = useStore()
  const hasIncludedPackages = include.every((p) => currentUser.hasPackage(p))
  const noExcludedPackages = exclude.every((p) => !currentUser.hasPackage(p))

  if (hasIncludedPackages && noExcludedPackages) return <>{children}</>
  return null
}
